<template>
	<v-page>
		<v-banner :bg="data?.entry?.heroVideo" class="hero">
			<div
				class="flex flex-col gap-40 h-full justify-content-between container-2xl">
				<p class="hero__title">
					Innovative livestock handling equipment
					<strong>you can trust</strong>
				</p>

				<v-title class="hero__copy" tag="h1" :value="data?.entry?.title" />
			</div>
		</v-banner>

		<section class="my-100 container-2xl">
			<v-title
				class="display-2"
				tag="h2"
				:value="data?.entry?.solutionsTitle" />

			<v-copy
				class="font-bold mt-40 text-lg lg:ml-auto lg:max-w-1/2"
				:value="data?.entry?.solutionsCopy" />
		</section>

		<v-home-solutions
			v-if="data?.entry?.solutions?.length"
			class="my-100"
			:solutions="data?.entry?.solutions" />

		<section
			v-if="data?.entry?.integratedSolutions?.length"
			class="integrated-solutions my-100 container-2xl">
			<h2 ref="rotatingText" class="rotating-text">
				<span
					v-for="(item, index) in data?.entry?.integratedSolutions"
					:key="index"
					class="rotating-text__word">
					<span v-html="item.icon.inline"></span>
					{{ item.title }}
				</span>
			</h2>

			<div>
				<v-copy
					class="font-bold text-lg"
					:value="data?.entry?.integratedSolutionsCopy" />

				<v-button
					:is="nuxtLink"
					class="mt-30"
					color="primary"
					fill="outline"
					shape="round"
					:to="localePath('/integrated-solutions')"
					type="button"
					value="The Te Pari Solution" />
			</div>
		</section>

		<v-carousel
			v-if="data?.entry?.products?.length"
			class="my-100"
			:options="{
				spaceBetween: 20,
			}">
			<template #content>
				<v-title class="h1" tag="h2" :value="data?.entry?.productsTitle" />

				<v-button
					:is="nuxtLink"
					class="mt-30"
					color="primary"
					fill="outline"
					shape="round"
					:to="localePath('/shop')"
					type="button"
					value="Visit Our Shop" />
			</template>

			<v-slide
				v-for="(product, index) in data.entry.products"
				:key="index"
				class="slide max-w-300">
				<v-product-card class="h-full" heading-tag="h3" :product="product" />
			</v-slide>
		</v-carousel>

		<section class="flex flex-wrap gap-40 my-100 container-2xl">
			<div class="clip-corner-lg flex-full lg:flex-1/2">
				<v-thumbnail v-if="storyThumbnail" class="h-full" ratio="16:10">
					<v-parallax class="h-full w-full">
						<v-image
							:alt="storyThumbnail.alt"
							class="h-full w-full"
							:height="storyThumbnail.height"
							ratio="none"
							:src="storyThumbnail.url"
							:width="storyThumbnail.width"
					/></v-parallax>

					<template #overlay>
						<v-button
							color="white"
							fill="solid"
							icon="tepari:play"
							shape="round"
							size="lg"
							type="button"
							value="The Te Pari Story"
							@click="storyVideoLightbox?.open()" />
					</template>
				</v-thumbnail>
			</div>

			<div class="lg:clip-offset-lg flex-full lg:flex-1/2">
				<v-title class="h1 text-brand" :value="data?.entry?.storyTitle" />

				<v-copy
					class="font-bold mt-30 text-md"
					:value="data?.entry?.solutionsCopy" />

				<v-button
					:is="nuxtLink"
					class="mt-30"
					color="primary"
					fill="outline"
					shape="round"
					:to="localePath('/about')"
					type="button"
					value="Read Our Story" />
			</div>
		</section>

		<section v-if="data?.articles?.length" class="bg-brand my-100 py-40">
			<div class="container-2xl">
				<div class="align-items-center flex flex-wrap gap-20">
					<v-title
						class="h1 flex-full text-white md:flex-fill"
						tag="h2"
						value="Latest articles." />

					<div class="flex-full md:flex-auto">
						<v-button
							:is="nuxtLink"
							color="white"
							fill="outline"
							shape="round"
							:to="localePath('/blog')"
							type="button"
							value="View More Articles" />
					</div>
				</div>

				<v-featured-articles :articles="data.articles" class="mt-20" />
			</div>
		</section>

		<section
			v-if="data?.entry?.testimonials?.length"
			class="my-100 container-xl">
			<v-title
				class="h1 text-center"
				tag="h2"
				value="Hear what our customers have to say." />

			<v-slider
				ref="testimonialsSliderEl"
				class="mt-40"
				:options="{
					autoplay: {
						delay: 5000,
					},
					effect: 'fade',
					loop: data.entry.testimonials.length > 1,
				}">
				<v-slide
					v-for="(testimonial, index) in data.entry.testimonials"
					:key="index">
					<div class="testimonial">
						<v-craft-image
							class="opacity-50 absolute inset-0 z-0"
							ratio="none"
							:value="testimonial.image" />

						<div class="m-auto relative text-white max-w-600 z-1">
							<v-copy class="font-bold text-md" :value="testimonial.quote" />

							<p v-if="testimonial.quoteAuthor" class="mt-10">
								{{ testimonial.quoteAuthor }}
							</p>

							<v-button
								v-if="testimonial.relatedArticle.length"
								class="mt-40"
								color="white"
								fill="outline"
								shape="round"
								type="button"
								value="Read their story" />
						</div>
					</div>
				</v-slide>

				<template #controls>
					<v-slider-dots
						v-if="data.entry.testimonials.length > 1"
						class="text-white"
						:slider="testimonialsSliderEl" />

					<v-button
						:is="nuxtLink"
						class="absolute right-15 top-15 z-1"
						color="white"
						fill="solid"
						shape="round"
						:to="localePath('/blog?category=case-studies')"
						value="View all case studies" />
				</template>
			</v-slider>
		</section>

		<v-lightbox
			id="lightbox-home-story"
			ref="storyVideoLightbox"
			:media="storyVideo" />
	</v-page>
</template>

<script lang="ts" setup>
	import Lightbox from '@/components/common/lightbox/lightbox.vue'
	import { useSEOmatic } from '@/composables/seomatic'
	import type Slider from '~/components/common/slider/slider.vue'
	import { getLightboxMedia } from '~/utilities/get-lightbox-media'
	import { getThumbnails } from '~/utilities/get-media-thumbnails'

	const { data } = await useApi('/api/entry/home')

	const { $gsap } = useNuxtApp()

	const nuxtLink = resolveComponent('NuxtLink')

	const localePath = useLocalePath()

	const rotatingText = ref<HTMLElement>()

	const testimonialsSliderEl = ref<InstanceType<typeof Slider>>(null!)

	const storyVideoLightbox = ref<InstanceType<typeof Lightbox>>(null!)

	const storyVideo = computed(() =>
		getLightboxMedia(data.value?.entry?.storyVideo),
	)

	const storyThumbnail = computed(() =>
		getThumbnails(data.value?.entry?.storyVideo).pop(),
	)

	onMounted(() => {
		initRotatingText()
	})

	function initRotatingText() {
		if (!rotatingText.value) return

		const words = rotatingText.value.querySelectorAll('.rotating-text__word')

		if (words.length < 2) return

		const tl = $gsap.timeline({
			paused: true,
			repeat: -1,
			repeatDelay: 0,
		})

		words.forEach((word) => {
			tl.set(word, {
				y: 40,
				opacity: 0,
			})

			tl.to(word, {
				y: 0,
				opacity: 1,
				duration: 0.5,
			})

			tl.to(word, {
				y: -40,
				opacity: 0,
				duration: 0.5,
				delay: 1,
			})
		})

		tl.play()
	}

	useSEOmatic(data.value?.entry?.seomatic)
</script>

<style lang="scss" scoped>
	.hero {
		--banner-intensity: 0.5;

		background-color: $brand-dark;
		height: calc(75vh - var(--header-height));
		height: calc(75dvh - var(--header-height));

		@include media-breakpoint-up('md') {
			height: calc(100vh - var(--header-height));
			height: calc(100dvh - var(--header-height));
		}

		&__title {
			font-size: clamp(2rem, 3vw, 3rem);
			font-weight: 700;
			line-height: 1.2;
			text-align: center;

			@include media-breakpoint-up('md') {
				text-align: left;
			}

			strong {
				display: block;
				font-size: clamp(4rem, 10vw, 10rem);
				font-family: $font-secondary;
				text-transform: uppercase;
			}
		}

		&__copy {
			font-size: clamp(1.6rem, 3vw, 2rem);
			font-weight: 700;
			text-align: center;

			@include media-breakpoint-up('md') {
				text-align: left;
				max-width: 600px;
			}
		}
	}

	.integrated-solutions {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		align-items: center;

		@include media-breakpoint-up('lg') {
			flex-direction: row;
		}

		> :first-child,
		> :last-child {
			flex: 1 1 100%;
			width: 100%;
		}

		> :first-child {
			@include media-breakpoint-up('lg') {
				flex: 0 0 auto;
				width: auto;
			}
		}

		> :last-child {
			border-top: 1px solid $gray-300;
			padding: 40px 0;
			margin: 40px 0 0 0;

			@include media-breakpoint-up('lg') {
				flex: 1 1 0%;
				width: auto;
				border-top: none;
				border-left: 1px solid $gray-300;
				padding-left: 40px;
				margin: 0 0 0 40px;
			}
		}
	}

	.rotating-text {
		display: grid;
		color: $brand;
		font-size: clamp(4rem, 10vw, 6rem);
		font-weight: 700;
		line-height: 1.2;
		white-space: nowrap;

		> span {
			grid-row: 1;
			grid-column: 1;
			display: flex;
			align-items: center;
			gap: 20px;
			opacity: 0;
		}

		:deep(svg) {
			width: 60px;
			height: 60px;

			@include media-breakpoint-up('md') {
				width: 80px;
				height: 80px;
			}
		}
	}

	.testimonial {
		display: grid;
		place-content: center;
		position: relative;
		padding: 60px 20px;
		text-align: center;
		isolation: isolate;
		color: $white;
		min-height: 450px;
		background-color: $brand-dark;
	}
</style>

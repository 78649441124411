<template>
	<nuxt-link class="solution" :to="link?.url">
		<twic-picture
			:alt="thumbnail.alt"
			class="solution__img"
			:height="thumbnail.height"
			ratio="1:1"
			:src
			:width="thumbnail.width" />

		<div class="solution__overlay">
			<div class="solution__header">
				<figure v-html="solution.icon.inline" class="flex-0 h-60 w-60"></figure>

				<v-title tag="h2" :value="solution.title" />
			</div>

			<div class="solution__content">
				<v-copy class="text-sm" :value="solution.copy" />

				<v-button
					is="span"
					class="mt-20"
					color="white"
					fill="outline"
					shape="round"
					:value="link?.text" />
			</div>
		</div>
	</nuxt-link>
</template>

<script lang="ts" setup>
	import { getLink } from '~/utilities/get-link'
	import { getImageThumbnail } from '~/utilities/get-media-thumbnails'
	import { getTwicPicsUrl } from '~/utilities/get-twicpics-url'
	import type { Solution } from '~~/server/api/entry/home'

	const props = defineProps<{
		solution: Solution
	}>()

	const link = computed(() => getLink(props.solution.link))

	const thumbnail = computed(() => getImageThumbnail(props.solution.image))

	const src = computed(() => getTwicPicsUrl(thumbnail.value.url))
</script>

<style lang="scss">
	.solution {
		isolation: isolate;
		position: relative;
		background-color: $brand-dark;
		color: $white;
		transition: flex-grow 400ms;

		&:hover,
		&:focus,
		&:focus-within {
			@include media-breakpoint-up('xl') {
				flex-grow: 1.25 !important;

				&:before {
					opacity: 0;
				}

				.solution__img {
					opacity: 0.3;
				}

				.solution__content {
					opacity: 1;
					transform: translateY(0);
				}
			}
		}

		&__img {
			position: absolute;
			inset: 0;
			z-index: -2;
			transition: opacity 400ms;
			opacity: 0.3;

			@include media-breakpoint-up('xl') {
				opacity: 1;
			}
		}

		&:before {
			@include media-breakpoint-up('xl') {
				content: '';
				position: absolute;
				z-index: -1;
				top: 0;
				left: 0;
				width: 100%;
				height: 33%;
				transition: opacity 400ms;
				background: linear-gradient(
					180deg,
					rgba($gray-900, 0.6) 20%,
					transparent 100%
				);
			}
		}

		&__overlay {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			margin: auto;
			padding: 40px 20px;
			overflow: hidden;
			width: 100%;
			height: 100%;

			@include media-breakpoint-up('xl') {
				max-width: 320px;
			}
		}

		&__header {
			display: flex;
			align-items: center;
			gap: 10px;

			h2 {
				flex: 1 1 auto;
				font-size: $text-xl;
				font-family: $font-secondary;
				text-transform: uppercase;
				line-height: 1;
			}
		}

		&__content {
			margin-top: 20px;

			@include media-breakpoint-up('xl') {
				margin-top: auto;
				opacity: 0;
				transform: translateY(calc(100% + 40px));
				transition: 400ms;
				transition-property: opacity, transform;
			}
		}
	}
</style>

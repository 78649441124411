<template>
	<section
		ref="solutionsEl"
		class="solutions"
		:style="{
			'--solution-count': `${solutions.length}`,
		}">
		<v-home-solution
			v-for="(solution, index) in solutions"
			:key="index"
			:solution="solution" />
	</section>
</template>

<script lang="ts" setup>
	import type { Solution } from '~~/server/api/entry/home'

	const props = defineProps<{
		solutions: Solution[]
	}>()

	const solutionsEl = ref<HTMLElement>()

	onMounted(() => {
		setWidth()
		useResizeObserver(solutionsEl, setWidth)
	})

	function setWidth() {
		solutionsEl.value?.style.setProperty(
			'--solutions-width',
			`${solutionsEl.value?.clientWidth ?? 0}px`,
		)
	}
</script>

<style lang="scss" scoped>
	.solutions {
		display: flex;
		gap: 1px;
		flex-wrap: wrap;
		background-color: $white;
		overflow: hidden;

		@include media-breakpoint-up('xl') {
			min-height: 80vh;
			min-height: 80dvh;
		}

		> * {
			flex: 1 1 100%;

			@include media-breakpoint-up('md') {
				flex: 1 1 calc(50% - 0.5px);
			}

			@include media-breakpoint-up('xl') {
				flex: 1 1 0%;
			}
		}
	}
</style>
